.capture {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  overflow-y: scroll;
  max-height: 100vh;

  &__logo {
    width: 80vw;
    max-width: 600px;
    margin: 4rem 0;
  }

  &__container {
    position: relative;
    width: 100%;
    max-width: 700px;
    margin: auto;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__selection {
    width: 100%;
    margin-bottom: 1.5rem;

    > img {
      width: 100%;
    }

    > button {
      position: absolute;
      top: 1rem;
      right: 3rem;
      background-color: white;
      width: 50px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer !important;
      box-shadow: -2px 2px 0px 0px black;
      > img {
        width: 15px;
      }
      color: black;
      font-size: 16px;
    }
  }

  &__list {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 4rem;

    > button {
      position: relative;
      flex: 1;
      margin-left: 1rem;
      cursor: pointer !important;

      &:nth-of-type(1) {
        margin-left: 0;
      }

      > img {
        width: 100%;
        pointer-events: none;

        &[data-selected="true"] {
          border: 4px solid white;
        }
      }

      > div {
        top: 16px;
        left: 4px;
        position: absolute;
        pointer-events: none;
        width: calc(100% - 8px);
        height: calc(100% - 36px);
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
