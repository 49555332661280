.landing {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  overflow-y: scroll;
  max-height: 100vh;

  &__logo {
    width: 50vw;
    max-width: 400px;
    margin: 4rem 0;
  }
}
