html,
body,
#root {
  background-color: black;
  position: fixed;
  overflow: hidden;
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
  touch-action: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) and
      (-webkit-touch-callout: none) {
      width: -webkit-fill-available;
      height: -webkit-fill-available;
    }
  }
}

main,
#root {
  transform: scale(0.81);
  width: 100vw;
  height: 100vh;
}

#root {
  overflow: visible;
}

canvas {
  z-index: 0;
  position: absolute;
  object-fit: cover;
  width: 100% !important;
  height: 100% !important;
  left: 0;
  top: 0;
  margin: auto;
}

#root {
  z-index: 1;
  background-color: transparent;
}

h3 {
  font-size: clamp(1.5rem, -0.3rem + 10vw, 2.75rem);
}

button,
p,
span {
  font-family: "DM Mono", monospace;
  text-transform: uppercase;
}
