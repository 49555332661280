.booth {
  width: 100vw;
  height: 100vh;

  &__touch_absorber {
    position: absolute;
    z-index: 1000;
    width: 200vw;
    height: 200vh;
    cursor: none;
  }

  &__instructions {
    font-size: 1.5vh;
    text-align: center;
    position: absolute;
    top: 22.5vh;
  }

  &__flash {
    position: absolute;
    width: 100vw;
    height: 100vh;
    animation: out 0.3s forwards;
    &[data-state="visible"] {
      animation: in 0.05s forwards;
    }
  }

  &__bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    top: 0;
    left: 0;
    height: 4vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    border-bottom: 2px solid rgba(0, 0, 0, 0.8);
  }
  &__bar_fill {
    position: absolute;
    left: 0;
    background-color: white;
    height: 100%;
    width: var(--elapsed);
    z-index: 5;
  }
  &__bar_dot {
    background-color: white;
    border: 0.25vh solid black;
    height: 2vh;
    width: 2vh;
    border-radius: 1vh;
    z-index: 10;
  }

  &__logo {
    height: 9vh;
    margin-top: 8vh;
    margin-bottom: 14vh;
  }

  &__content {
    background-color: transparent;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    &[data-background="true"] {
      background-color: white;
    }

    &[data-no-center="true"] {
      justify-content: flex-start;
    }

    > p {
      margin-top: 2rem;
    }
  }
  &__number_field {
    position: relative;
    width: 50%;
    max-width: 500px;
    font-size: 3vh;
    margin-bottom: 2rem;
    height: 8vh;

    > div {
      background-color: rgba(0, 0, 0);
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      width: 100%;
      height: 100%;
      z-index: 10;
      position: relative;
      border-radius: 1vh;
    }

    &::before {
      content: "";
      background: var(--gradient);
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0;
      filter: blur(20px);
    }
  }
  &__num_pad {
    position: relative;
    height: 40vh;
    width: 50%;
    max-width: 500px;

    > div {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      > button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ec60dc;
        color: white;
        width: calc(30% - 1vw);
        height: calc(20% - 1vh);
        font-size: 3vh;
        border-radius: 1vh;

        span {
          transition: transform 0.5s;
        }

        &:hover,
        &:active {
          // background: ;

          span {
            transform: scale(1.25);
          }
        }
      }
    }
  }

  &__review_image {
    background-color: black;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__cta {
    position: absolute;
    bottom: 5vh;
    width: 50%;
    max-width: 500px;
    height: 8vh;
    background-color: white;
    color: black;
    font-size: 2.5vh;
    border-radius: 1vh;
    transition: opacity 0.5s linear;

    &:disabled {
      opacity: 0.4;
      pointer-events: none;
      &::before {
        opacity: 0;
      }
    }

    > div {
      background-color: black;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: white;
      width: 100%;
      height: 100%;
      z-index: 10;
      position: relative;
      border-radius: 1vh;

      > span:nth-of-type(2) {
        color: white;
        font-size: 1.25vh;
        margin-top: 0.25vh;
        opacity: 0.7;
      }
    }

    &::before {
      content: "";
      background: var(--highlight);
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      z-index: 0;
      filter: blur(20px);
    }
  }

  &__redo {
    position: absolute;
    bottom: 5vh;
    width: 8vh;
    left: 5vh;
    max-width: 500px;
    height: 8vh;
    color: black;
    font-size: 1.5vh;
    border-radius: 1vh;
    transition: opacity 0.5s linear;

    &:disabled {
      opacity: 0.4;
      pointer-events: none;
      &::before {
        opacity: 0;
      }
    }

    > div {
      background-color: rgb(50, 50, 50);
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      width: 100%;
      height: 100%;
      z-index: 10;
      position: relative;
      border-radius: 1vh;

      img {
        position: relative;
        top: 0.1vh;
      }
    }

    &::before {
      content: "";
      background: var(--highlight);
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      z-index: 0;
      filter: blur(20px);
    }
  }
}

@keyframes in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
